// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-bluefin-gatsby-plugin-fisherman-customer-blog-templates-hibiscus-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-customer-blog/templates/hibiscus/Template.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-customer-blog-templates-hibiscus-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-hibiscus-service-category-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/hibiscus/ServiceCategoryTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-hibiscus-service-category-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-hibiscus-service-item-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/hibiscus/ServiceItemTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-hibiscus-service-item-template-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-classes-jsx": () => import("./../../../src/pages/classes.jsx" /* webpackChunkName: "component---src-pages-classes-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-process-jsx": () => import("./../../../src/pages/our-process.jsx" /* webpackChunkName: "component---src-pages-our-process-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */)
}

